<template>
  <div class="container_about_us">
    <Navbar />
    <b-container fluid class="container_about_us__row1">
      <b-row align-v="center">
        <b-col class="container_about_us__row1--text" cols="12" md="5">
          <h1 class="text-center mb-4">We are Do Genius On</h1>
          <p class="mb-0">
            Do Genius On was born from the idea of improving performance in the
            development of native applications, today it is a team made up of
            talented young people who are in different parts of Colombia and one
            of our leaders is in the United States.
          </p>
        </b-col>
        <b-col cols="12" md="7" align-self="center" class="text-center">
          <img src="@/assets/img/team_dgo.png" class="img-fluid" />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="container_about_us__row2" fluid>
      <b-row>
        <b-col align-self="center" class="container_about_us__row2--card"
          order="2" order-md="1" cols="12" md="6">
          <b-card no-body class="mx-auto">
            <h4 class="mb-0">
              Trials and failures, until one night we came up with the best
              idea!
            </h4>
          </b-card>
        </b-col>
        <b-col align-self="center" class="container_about_us__row2--text"
          order="1" order-md="2" cols="12" md="6">
          <div class="w-75">
            <h3>
              Check out our different projects and feel free to
              <b-button variant="outline-secondary" pill
                @click="$bvModal.show('contact-us')">contact us</b-button>
              if you have any questions.
            </h3>
            <p class="mb-0">
              We are growing rapidly and are always looking for new talent to
              join our team of geniuses to continue building the future.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="container_about_us__row3" fluid>
      <b-row>
        <b-col>
          <h1 class="text-center">
            Let's keep building technology for technology builders!
          </h1>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="container_about_us__row4" fluid>
      <b-row>
        <b-col>
          <h1 class="text-center mb-3">Discover our other creations</h1>
          <p class="text-center mb-5">
            Our MCSS library is totally free and if you want to know about us go
            to Do Genius On
          </p>
          <div class="text-center">
            <b-button variant="primary" href="https://getmcss.com/"
              target="_blank" class="px-5 py-2 me-5">GET MCSS</b-button>
            <b-button variant="outline-secondary" href="https://dogeniuson.com/"
              target="_blank" class="px-5 py-2">DO GENIUS ON</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="container_about_us__row5" fluid>
      <b-row>
        <b-col>
          <img src="@/assets/img/img_map_about_us.png" class="img-fluid" />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="container_about_us__row6" fluid>
      <b-row>
        <b-col>
          <h3 class="text-center">
            Our team is located in different cities in Colombia and USA, we hope
            that this map will be filled with new talents very soon.
          </h3>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer2.vue";

export default {
  name: "AboutUs",
  components: {
    Navbar,
    Footer,
  },
};
</script>
